.modal-container {
  position: absolute;
  background-color: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal {
  background-color: #222;
  width: 400px;
  border-radius: 8px;
  overflow: hidden;
  filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.5));
}

.modal-body {
  padding: 8px;
}

.modal-title {
  font-size: 12pt;
  font-weight: 700;
  background-color: #111;
  padding: 8px;
}

.modal-buttons {
  background-color: #111;
  padding: 8px;
}