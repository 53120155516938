@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');


* {
  font-family: Oxygen;
  color: #fff;
  box-sizing: border-box;
  padding: 0; margin: 0;
}

.code, code {
  font-family: 'consolas';
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid #444;
  background-color: #222;
}

html, body {
  padding: 0;
  margin: 0;
}

body {
  background-color: #111;
}

select {
  background-color: #222;
}

button {
  cursor: pointer;
  background-color: #c2a326;
  color: #222;
  padding: 8px;
  border-radius: 8px;
  border: none;
}

input {
  background-color: #222;
  border: none;
  padding: 8px;
  border-radius: 8px;
}

.text-small, .text-sm {
  font-size: 0.7em;
}

.text-center {
  text-align: center;
}

.nav-menu {
  ul {
    padding: 0;
  }
  li {
    background-color: #222;
    display: block;
    padding: 8px 4px;
    font-size: 0.875rem;
    font-weight: 700;
    border-radius: 4px;
    cursor: pointer;
  }
  li:hover {
    background-color: #333;
  }
}

button {
  transition: opacity 0.2s ease;
}

button:hover {
  opacity: 0.8;
}