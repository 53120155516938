.login-dialog {
  width: 300px;
  background-color: #333;
  padding: 40px;
  border-radius: 12px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  input, button {
    width: 100%;
    margin-top: 8px;
  }
}