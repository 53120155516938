.mini-progress-bar-container {
  height: 10px;
  margin-top: 2px;
  display: flex;
  width: 100%;
}

.mini-progress-bar {
  flex: 1;
  border: 1px solid #fff;
  margin-top: 1px;
  border-radius: 4px;
  height: 8px;
}

.mini-progress-bar-value {
  background-color: #fff;
  height: 6px;
}

.mini-progress-bar-label {
  width: 40px;
  line-height: 10px;
  font-size: 10px;
}