:root {
  --blue: #203847;
  --blue-active: #3774e7;
  --bg-dark-fg: #fff;
  --bg-light-fg: #111;
}

.bg-yes {
  background-color: rgb(37, 175, 44);
}
.bg-no {
  background-color: rgb(197, 59, 35);
}
.bg-gray {
  background-color: #aaa;
}
.bg-blue {
  background-color: var(--blue);
}
.bg-blue-active {
  background-color: #3774e7;
}

.btn-blue {
  background-color: var(--blue);
  color: var(--bg-dark-fg);
}

.active.btn-blue { background-color: var(--blue-active) }