.cvr-container {
  background-color: #222;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.cvr-display-container {
  width: 240px;
  background: #432f52;
  border-radius: 8px;
}

.cvr-display-display-container {
  background: #111;
  border-radius: 8px;
  padding: 8px;
}

.cvr-display-title {
  font-weight: 700;
  font-size: 13px;
  padding-bottom: 4px;
}

.cvr-display-v-buttons {
  padding: 0;
  margin: 0;
  button {
    width: 100%;
    height: 100%;
    background-color: #6d488a;
    color: #fff;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.button-right-rounding-v {
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  button:first-child {
    border-bottom-right-radius: 0;
  }
  button:last-child {
    border-top-right-radius: 0;
  }
}

.button-left-rounding-v {
  button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button:first-child {
    border-bottom-left-radius: 0;
  }
  button:last-child {
    border-top-left-radius: 0;
  }
}

.padded {
  padding: 8px;
}

.button-control {
  font-size: 14px;
}