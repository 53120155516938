.app-layout-container {
  width: 100vw;
  height: calc(100vh - 68px);
  display: flex;
}

.al-nav-menu-c {
  width: 200px;
  background-color: #121212;
  padding: 4px;
  border-right: 2px solid #222;
  li {
    margin-bottom: 2px;
  }
}

@media only screen and (max-width: 600px) {
  .al-nav-menu-c {
    width: 50px;
    overflow: hidden;
  }
}

.w-100 { width: 100% }

.al-content-c {
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.al-top-menu-c {
  background-color: #222;
  width: 100%;
  height: 68px;
}

.content {
  padding: 30px 40px;
  width: 100%;
}

.pane {
  background: #181818;
  padding: 30px 40px;
  font-size: 22px;
  font-weight: 700;
  color: #aaa;
}

.f, .flex {
  display: flex;
}

.f-wrap { flex-wrap: wrap; }

.f-gap { gap: 8px; }

.v-flex {
  display: flex;
  flex-direction: column;
}

.h-flex {
  display: flex;
}

.f-1 { flex: 1; }
.f-2 { flex: 2; }
.f-3 { flex: 3; }
.f-4 { flex: 4; }
.f-5 { flex: 5; }
.f-6 { flex: 6; }
.f-7 { flex: 7; }
.f-8 { flex: 8; }
.f-9 { flex: 9; }

.svg-icon {
  flex: 0 0 auto;
  display: inline-block;
  font-size: 1rem;
  text-align: center;
}

button:active {
  transform: scale(0.98); 
  /* Scaling button to 0.98 to its original size */ 
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
}